import React, { useState, useCallback, useEffect } from 'react';
import HeaderFinancialPortal from '@components/FinancialPortal/Header/Header';
import Services from '../ServicesPage';
import RokinBenefits from '../Benefits/BenefitsPage';
import { makeStyles } from '@material-ui/core';

const ServicesLayout = () => {
  const [isSelect, setSelect] = useState(0);
  const classes = useStyles();
  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  return (
    <>
      <HeaderFinancialPortal
        setSelect={setSelect}
        isSelect={isSelect}
        infoPopup={infoButtonHandler}
        which={'services'}
      >
        {isSelect === 0 && (
          <div className={classes.missionsData}>
            <Services />
          </div>
        )}
        {isSelect === 1 && (
          <div className={classes.missionsData}>
            <RokinBenefits />
          </div>
        )}
      </HeaderFinancialPortal>
    </>
  );
};

export default ServicesLayout;

const useStyles = makeStyles((theme) => ({
  hiddenDisplay: {
    display: 'none',
  },
  sidebarButton: {
    width: '3.938rem',
    height: '4.563rem',
    backgroundColor: '#393e44',
    borderRadius: '15px',
    opacity: 0.25,
    display: 'flex',
    position: 'sticky',
  },
  missionsData: {
    marginTop: '2rem',
    paddingBottom: '5rem',
    [theme.breakpoints.down(1280)]: {
      height: 'auto',
      marginTop: '-2rem!important',
      background: '#00323C',
    },
  },
  popUpContainer: {
    zIndex: 1000,
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.8)',
    height: '100rem',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '10%',
    [theme.breakpoints.down(1280)]: {
      paddingTop: '35%',
    },
  },
  outerBorder: {
    width: '43.938rem',
    height: '20.875rem',
    borderRadius: '40px',
    border: '1px solid #ffffff',
    padding: '0.25rem',
    [theme.breakpoints.down(1280)]: {
      width: '21.063rem',
      height: '28.188rem',
    },
  },
  popUp: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '35px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    [theme.breakpoints.down(1280)]: {
      borderRadius: '35px',
    },
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  popUpVector: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: '#ffffff',
    borderRadius: '2px',
    margin: '0.7rem 0 0 0',
    [theme.breakpoints.down(1280)]: {},
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '1.43',
    color: '#393e44',
    margin: '1.5rem 0 0 0',
  },
  subtext: {
    fontFamily: 'Noto Sans, Montserrat',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '1.67',
    color: '#393e44',
    margin: '1.5rem 0 0 0',
  },
  buttonGotItContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1280)]: {
      margin: '2.5rem 0 0 0',
    },
  },
  buttonGotIt: {
    margin: '1.2rem 0 0 0',
    width: '3rem',
  },
  textButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  rokipediaPopUpTitle: {
    fontFamily: 'Poppins, Montserrat',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '#393e44',
    margin: '0 75% 0 0 ',
    [theme.breakpoints.down(1280)]: {
      margin: '0 47% 0 3% ',
    },
  },
  rokipediaPopUpButton: {
    margin: '-1rem 0 0 0 ',
  },
  image: {
    margin: '1.5rem 1rem 0 0',
    [theme.breakpoints.down(1280)]: {
      margin: '0.7rem 1rem 0 0',
    },
  },
  image2: {
    margin: '0.7rem 1rem 0 0',
    [theme.breakpoints.down(1280)]: {
      margin: '-1.6rem 1rem 0 0',
    },
  },
  strongText: {
    fontWeight: 'bolder',
    color: 'black',
  },
}));
