import React, { useCallback, useContext, useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { IconButton, makeStyles, useMediaQuery } from '@material-ui/core';
import {
  ACCESSORIES_ICON,
  ALFRED_ICON,
  CREDITS_ICON,
  EXCLAMATION_ICON,
  INSURANCE_ICON,
  INVERSIONS_ICON,
  RECURRING_PAYMENT_ICON,
  PARTNERS_02_ICON,
} from 'images/GoogleCloud';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Route } from '@interfaces';
import { LayoutMobileInvestments } from '@components/Investments/Layout';

const Services = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1270px)');
  const [openPlans, setOpenPlans] = useState(false);

  return (
    <>
      <section
        className={
          openPlans && isMobile
            ? classes.hidden
            : `${classes.section} ${classes.sectionHeight}`
        }
      >
        <div className={classes.divSection}>
          <div className={classes.services}>
            <span></span>
          </div>
        </div>
        <div className={classes.listSections}>
          {list.map((icon) => (
            <section
              className={`${classes.icon} ${icon.disabled && classes.disabled}`}
              key={icon.id}
              onClick={() => {
                navigate(icon.onClick);
              }}
            >
              <article>
                <img src={icon.image} className={classes.leftIcon} />
                <div className={classes.column}>
                  <span
                    className={
                      icon.strongText ? classes.strongTitle : classes.title
                    }
                  >
                    {icon.name}
                  </span>
                  <div className={classes.subtitle}>{icon.subtitle}</div>
                </div>
              </article>
              <span>
                <IconButton className={classes.pointer}>
                  <ArrowForwardIosIcon className="arrow2" />
                </IconButton>
              </span>
            </section>
          ))}
        </div>
      </section>{' '}
      {isMobile && !openPlans && (
        <LayoutMobileInvestments activeServices>
          <></>
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default Services;

const useStyles = makeStyles((theme) => ({
  divSection: {
    [theme.breakpoints.down(1200)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start!important',
      alignItems: 'start!important',
      margin: '0 auto',
      width: '100%',
    },
  },
  layoutOnBoarding: {
    background: (openPlans) =>
      openPlans
        ? '#084554'
        : 'linear-gradient(151deg, rgb(10, 86, 105) 6%, rgb(0, 169, 194) 85%)',

    minHeight: '100vh',
    fontFamily: 'Montserrat',
    '& div': {
      [theme.breakpoints.down(1200)]: {
        marginTop: '0px',
      },
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  section: {
    minHeight: '90vh',
    width: '70rem',
    margin: '2rem auto 0',
    [theme.breakpoints.down(1200)]: {
      margin: '3rem auto 0',
      width: '100%',
    },
    '& >div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '2rem',
    },
  },
  sectionHeight: {
    [theme.breakpoints.down(1200)]: {
      minHeight: '73vh',
      '& >div': {
        justifyContent: 'start',
      },
    },
  },
  services: {
    display: 'flex',
    fontSize: '2.25rem',
    gap: '2rem',
    [theme.breakpoints.down(1200)]: {
      justifyContent: 'space-between',
      width: '90%',
      margin: '0 auto 1rem',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    '& span': {
      color: 'white',
    },
  },
  alfred: {
    display: 'flex',
    gap: '2rem',
    borderRadius: 20,
    border: `1px solid rgba(244,244,244,0.4)`,
    padding: '1rem 1.3rem',
    cursor: 'pointer',
    [theme.breakpoints.down(1200)]: {
      border: `none`,
      borderTop: `1px solid rgba(244,244,244,0.4)`,
      borderBottom: `1px solid rgba(244,244,244,0.4)`,
      borderRadius: 0,
      padding: '1rem 0.5rem',
      width: '90%',
      margin: '0 auto',
      gap: '1rem',
      justifyContent: 'space-between',
    },
    '& .image': {
      width: '3.5rem',
      height: '3.5rem',
    },
    '& .containerAlfred': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      [theme.breakpoints.down(1200)]: {
        width: '70%',
      },
    },
    '& .alfred': {
      color: 'white',
      [theme.breakpoints.down(1200)]: {
        fontSize: '1rem',
      },
      '& span': {
        color: '#00a9c2',
      },
    },
    '& .solicitude': {
      color: 'white',
      fontSize: '1.5rem',
      fontWeight: 500,
      [theme.breakpoints.down(1200)]: {
        fontSize: '1rem',
      },
    },
    '& .arrow': {
      width: '2rem',
      height: '3rem',
      color: 'white',
    },
  },
  listSections: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline!important',
    background: '#F4F3F3',
    borderRadius: 30,
    padding: '4.4rem 9.4rem',
    [theme.breakpoints.down(1200)]: {
      padding: '1rem 2rem',
      borderRadius: 20,
      minHeight: '73vh',
    },
    '& div': {
      flexDirection: 'column',
    },
    '& .arrow2': {
      width: '1.2rem',
      color: theme.palette.text.secondary,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
    paddingBottom: '1.5rem',
    marginBottom: '1.5rem',
    borderBottom: `1px solid #d6d6d6`,
    [theme.breakpoints.down(1200)]: {
      paddingBottom: '0.5rem',
      marginBottom: '0.5rem',
    },
    '& img': {
      width: '2rem',
    },
    '& article': {
      display: 'flex',
      gap: '1.2rem',
      width: '20rem',
      alignItems: 'center',
      '& img': {
        width: '1.5rem',
      },
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    fontWeight: 300,
    fontStyle: 'italic',
    fontFamily: 'Nunito',
    color: theme.palette.primary.main,
    marginTop: '0.2rem',
  },
  pointer: {
    cursor: 'pointer',
  },
  title: {
    marginTop: '0.2rem',
  },
  strongTitle: {
    marginTop: '0.2rem',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    color: '#393E44',
    [theme.breakpoints.down(1200)]: {
      fontSize: '1rem',
    },
  },
  hidden: {
    display: 'none',
  },
  leftIcon: {
    filter:
      'invert(52%) sepia(66%) saturate(2036%) hue-rotate(150deg) brightness(89%) contrast(101%)',
  },
}));

const list = [
  {
    id: 0,
    name: 'Tus servicios contratados',
    image: PARTNERS_02_ICON,
    onClick: Route.hiredServices,
    disabled: false,
    strongText: true,
  },
  {
    id: 1,
    name: 'Seguros',
    image: INSURANCE_ICON,
    onClick: Route.security,
    disabled: true,
    strongText: false,
  },
  {
    id: 2,
    name: 'Pagos recurrentes',
    image: RECURRING_PAYMENT_ICON,
    //subtitle: "Solicitud en curso",
    onClick: Route.recurringPayments,
    strongText: false,
  },
  {
    id: 3,
    name: 'Créditos',
    image: CREDITS_ICON,
    onClick: Route.credits,
    strongText: false,
  },
  {
    id: 4,
    name: 'Inversiones',
    image: INVERSIONS_ICON,
    onClick: Route.servicesInvestments,
    strongText: false,
  },
  {
    id: 5,
    name: 'Agenda tu coach financiero',
    image: ACCESSORIES_ICON,
    onClick: Route.advisory,
  },
];
